import React from "react";

export function shadowHide(advanced, proleague, rev = true, target) {
    target.classList.add('active')
    if (rev){
        advanced.classList.add('active-list-tab')
        advanced.classList.remove('hidden-list-tab');
        proleague.classList.add('hidden-list-tab')
        proleague.classList.remove('active-list-tab');
        document.querySelector('#pro-league').classList.remove('active')
    } else {
        advanced.classList.add('hidden-list-tab')
        advanced.classList.remove('active-list-tab');
        proleague.classList.add('active-list-tab')
        proleague.classList.remove('hidden-list-tab');
        document.querySelector('#advanced').classList.remove('active')
    }
}

export default class HeaderStrip extends React.Component {
    render() {
        return this.props.isMobile ? 
        <div className="outer-strip">
        <div className="header-strip-controller">
        <p className="bracket-name" id="pro-league" onClick={(e)=>{
            shadowHide(document.querySelector('.teams-list.advanced.mobile'), document.querySelector('.teams-list.pro-league.mobile'), false, e.target)
        }}>Pro League</p>
        </div>
        <div class="header-strip-wrapper mobile-teams-list animate__animated animate__fadeInDown">
        <div className="teams-list advanced mobile active-list-tab">
        <div className="teams-list inner advanced mobile">
        {this.props.teams.teams?.map(team => (
            <a href={'/team/' + team.id} class="team_wrapper" id={team.id}><img src={team.logo != '' && team.logo != null && team.logo != undefined ? team.logo : 'img/teamunknown.svg'} ></img></a>
        ))}
        </div>
        </div>
        </div> 
        </div>
        :
        <div class="header-strip-wrapper animate__animated animate__fadeInDown">
        <div className="teams-list divider hidden"></div>
        <div className="teams-list pro-league">
        <p className="bracket-name">Pro League</p>
        <div className="teams-list inner">
        {this.props.teams.teams?.map(team => (
            <a href={'/team/' + team.id} class="team_wrapper" id={team.id}><img src={team.logo != '' && team.logo != null && team.logo != undefined ? team.logo : 'img/teamunknown.svg'} ></img></a>
        ))}
        </div>
        </div>
    </div> 
    }
}
