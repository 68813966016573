import React from "react";
import Link from "./link";
import { SetStateAction } from "react";

export default class Hero extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          show: false
        };
      }
    render() {
        return (<div class="hero-wrapper">
                <div class="hero-protector">
                <div class="hero-left">
                    <div class="title_lg dark">ТАБЛИЦА PRO LEAGUE</div>
                    <div class="table-wrapper">
                        <div class="subwrapper">
                            <table class="ladder_container">
                                <thead><tr><td>МЕСТО</td><td>КОМАНДА</td><td>ОЧКИ</td><td>ИГР</td><td>ПОБ</td><td>НИЧ</td><td>ПОР</td></tr></thead>
                                {this.props.ladder.teams.map((team) => (<tr class="ladder-block-wrapper">
                                    <td>{team.place}</td><td><img class="img-sm" src={team.team.logo}></img></td><td>{team.points}</td><td>{team.wins + team.loses + team.draws}</td><td>{team.wins}</td><td>{team.draws}</td><td>{team.loses}</td>
                                </tr>)
                                )}
                            </table>
                        </div>
                    </div>
                </div>
                <div class="hero-right">
                    <img class="banner s2" onClick={(e)=>{
                            this.setState({ show: true })
                            e.target.style.display = 'none'
                    }} src="img/banner.png"></img>
                    <iframe className="video animate__animated animate__fadeInDown" style={{display: this.state.show ? 'block' : 'none' }} width="560" height="315" src="https://www.youtube-nocookie.com/embed/6GOlYxMXN7A?si=6JOW2BqrpfpWzEln&amp;controls=0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                </div>
                </div>
                <Link class={'news-show-more home'} link={'/ladder'} text={'Смотреть всю таблицу'} data-attr={'show-more'} />
        </div>)
    }
}