import React from "react";

export default class LogoStrip extends React.Component {
    render() {
        let isMobile = window.screen.width > 1100 ? false : true;
        if (!isMobile){
            return (<div class="logo-strip desktop">
                        <div class="image-wrapper"><img class="logo-strip-img" src="img/logo_syn.svg"></img></div>
                        <div class="image-wrapper"><img class="logo-strip-img" src="img/logo_coug.svg"></img></div>
                        <div class="image-wrapper"><img class="logo-strip-img" src="img/logo_dns.svg"></img></div>
            </div>)
        } else {
            return (<div class="logo-strip mobile">
                        <div class="image-wrapper"><img class="logo-strip-img" src="img/logo_syn_inverse.svg"></img></div>
                        <div class="image-wrapper"><img class="logo-strip-img" src="img/logo_coug_inverse.svg"></img></div>
                        <div class="image-wrapper"><img class="logo-strip-img" src="img/logo_dns_inverse.svg"></img></div>
            </div>)
        }
    }
}